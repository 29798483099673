<template>
    <div>
        <vs-card class="w-full h-full" :actionable="pageName != null">
          <div class="flex flex-col justify-between items-center h-full py-5" @click="cardClicked">
            <img class="w-1/4 mt-2" :src="image" />
            <span :class="`text-2xl font-bold text-center mt-5`" :style="`color: ${textColor};`">{{ title }}</span>
            <span :class="`text-2xl font-bold text-center mt-5`" :style="`color: ${subtitleColor};`">{{ subtitle }}</span>
            <span :class="`text-lg font-light text-center mt-5 underline`" :style="`color: #02d3de;`">{{ cta }}</span>
          </div>
        </vs-card>
    </div>
</template>

<script>

export default {
    name: 'information-card',
    props: {
        title: {
            type: String,
            defult: null
        },
        subtitle: {
            type: String,
            defult: null
        },
        subtitleColor: {
            type: String,
            defult: null
        },
        cta: {
            type: String,
            defult: null
        },
        image: {
            type: String,
            defult: null
        },
        pageName: {
            type: String,
            defult: null
        },
        textColor: {
            type: String,
            default: '#000000'
        },
    },
    data () {
        return {}
    },
    components: {},
    computed: {
        currentVenueId () {
            return this.$store.getters.currentVenueId
        },
    },
    created () {},
    methods: {
        cardClicked() {
            if(this.pageName) {
                this.$router.push({name: this.pageName, params: {id: this.currentVenueId }})
            }
        }
    },
    watch: {}
}
</script>

<style>

</style>
