<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
 
  <div id="dashboard-analytics">
    <!-- <booking-form-pop-up :bookingFormPopUpActive.sync="bookingFormPopUpActive" :currentBooking.sync="currentBooking" @updateCurrentBooking="currentBooking = $event" :isEdit="isBookingEdit" :startDate="startDate" :endDate="endDate" :enableBlockBooking.sync="enableBlockBooking"/> -->

    <div class="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-10 md:grid-cols-2 lg:grid-cols-3 lg:gap-10">
      <div class="col-span-1 md:col-span-2 lg:col-span-3">
        <div class="flex justify-between">
          <span class="text-xl md:text-4xl sm:text-2xl">{{ $t('statisticsCards.bookings.monthNameStatistics', {monthName: currentMonthText}) }}</span>
          <vs-select
          v-model="informationCardsFilters.event_date_type"
          >
          <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="item,index in [{text: $t('statisticsCards.filters.bookingDateTypeSelect.bookingDate'), value: 'created_at'}, {text: $t('statisticsCards.filters.bookingDateTypeSelect.eventDate'), value: 'start_date'}]" />
        </vs-select>
        </div>
      </div>
      <!-- {{ cardsData }} -->
      <information-card
        :title="$t('statisticsCards.bookings.bookingsCount', {bookingsCount: cardsData.real_bookings_count ? cardsData.real_bookings_count : 0})"
        :subtitle="$t('statisticsCards.bookings.totalPriceBookings', {totalPriceBookings: cardsData.total_price_real_bookings ? cardsData.total_price_real_bookings.toLocaleString() : 0})"
        :cta="$t('statisticsCards.bookings.knowMore')"
        :image="require('@/assets/images/icons/booking.svg')"
        pageName="bookings-list"
        textColor="#626262"
      />
      <information-card
        :title="$t('statisticsCards.bookings.leadsCount', {leadsCount: cardsData.leads_count ? cardsData.leads_count : 0})"
        :subtitle="$t('statisticsCards.bookings.totalLeadBookings', {totalLeadBookings: cardsData.total_lead_bookings ? cardsData.total_lead_bookings.toLocaleString() : 0})"
        :cta="$t('statisticsCards.bookings.convertIntoRealSales')"
        :image="require('@/assets/images/icons/people.svg')"
        pageName="bookings-leads"
        textColor="#626262"
      />
      <information-card
        :title="$t('statisticsCards.bookings.marketingCredit')"
        :subtitle="$t('statisticsCards.bookings.marketingCreditAmount', {marketingCredit: cardsData.marketing_credit ? cardsData.marketing_credit.toLocaleString() : 0})"
        :cta="$t('statisticsCards.bookings.knowMoreAboutBusinessMarketing')"
        subtitleColor="#05b317"
        :image="require('@/assets/images/icons/money.svg')"
        pageName="business-marketing"
        textColor="#626262"
      />
    </div>

    <div class="my-10"></div>

    <div class="vx-row flex justify-between mx-3">
      <div>
        <span class="text-3xl">{{ $route.meta.pageTitle }}</span>
      </div>
    </div>
    <div class="vx-row">

      <!-- LATEST BOOKINGS -->
      <div class="vx-col w-full mb-10">
        <vs-tabs v-model="activeTab">
          <vs-tab :label="$t('home.bookingsWaitingForYourAction')+ '(' +awaitingPlaceActionBookings.length+ ')'">
            <div class="con-tab-ejemplo">
              <vx-card :title="$t('home.bookingsWaitingForYourAction')">
                <bookings-table 
                ref="bookingsTableRef"
                :bookings="awaitingPlaceActionBookings"
                @updateBookingReceipt="bookingsRequest"
                />
            </vx-card>
            </div>
          </vs-tab>
          <vs-tab :label="$t('home.lastAddedBookings')+ '(' +lastAddedBookings.length+ ')'">
            <div class="con-tab-ejemplo">
              <vx-card :title="$t('home.lastAddedBookings')">
                  <bookings-table 
                  :bookings="lastAddedBookings"
                  ref="bookingsTableRef"
                  @updateBookingReceipt="bookingsRequest"
                  />
              </vx-card>
            </div>
          </vs-tab>
          <vs-tab :label="$t('home.upcomingBookings')+ '(' +upcomingBookings.length+ ')'">
            <div class="con-tab-ejemplo">
              <vx-card :title="$t('home.upcomingBookings')">
                  <bookings-table 
                  :bookings="upcomingBookings"
                  ref="bookingsTableRef"
                  @updateBookingReceipt="bookingsRequest"
                  />
              </vx-card>
            </div>
          </vs-tab>
          <vs-tab :label="$t('home.cancelledBookings')+ '(' +cancelledBookings.length+ ')'">
            <div class="con-tab-ejemplo">
              <vx-card :title="$t('home.bookingsWaitingForCustomerAction')">
                <bookings-table 
                :bookings="cancelledBookings"
                ref="bookingsTableRef"
                @updateBookingReceipt="bookingsRequest"
                />
              </vx-card>
            </div>
          </vs-tab>
          <vs-tab :label="$t('home.endedBookings')+ '(' +endedBookings.length+ ')'">
            <div class="con-tab-ejemplo">
              <vx-card :title="$t('home.bookingsWaitingForCustomerAction')">
                <bookings-table 
                :bookings="endedBookings"
                ref="bookingsTableRef"
                @updateBookingReceipt="bookingsRequest"
                />
              </vx-card>
            </div>
          </vs-tab>
          <vs-tab :label="$t('home.bookingsWaitingForCustomerAction')+ '(' +awaitingCustomerActionBookings.length+ ')'">
            <div class="con-tab-ejemplo">
              <vx-card :title="$t('home.bookingsWaitingForCustomerAction')">
                <bookings-table 
                :bookings="awaitingCustomerActionBookings"
                ref="bookingsTableRef"
                @updateBookingReceipt="bookingsRequest"
                />
              </vx-card>
            </div>
          </vs-tab>
          <vs-tab :label="$t('pages.businessEvents') + '(' + this.businessEventsCount + ')'">
            <div class="con-tab-ejemplo">
              <vx-card :title="$t('home.businessEvents')">
                <BusinessEventsTable />
              </vx-card>
            </div>
          </vs-tab>
        </vs-tabs>
      </div>

      

      

      
      
    </div>

    
  </div>
</template>

<script>

import BookingsTable from './Booking/BookingsTable.vue'
import BusinessEventsTable from '@/views/components/qosoor-components/BusinessEvents/BusinessEventsTable.vue'
import InformationCard from '@/components/statistics-cards/InformationCard.vue'
import moment from 'moment'
import axios from '@/axios'
export default {
  data () {
    return {
      activeTab: 0,
      currentUser: this.$store.getters.currentUser,
      bookingOptions: [],
      
      currentBookingOptionIsActive: false,
      editBookingOptionActive: false,
      editBookingOptionPromptActive: false,
      currentBookingOption: {},
      isEditBookingOption: false,

      informationCardsFilters: {
        event_date_type: 'start_date'
      },
      cardsData: {}
    }
  },
  components: {
    BookingsTable,
    BusinessEventsTable,
    InformationCard,
  },
  computed: {
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
    upcomingBookings () {
      return this.$store.state.dataList.upcomingBookings;
      
    },
    awaitingPlaceActionBookings () {
      return this.$store.state.dataList.awaitingPlaceActionBookings;
      
    },
    awaitingCustomerActionBookings () {
      return this.$store.state.dataList.awaitingCustomerActionBookings;
      
    },
    cancelledBookings () {
      return this.$store.state.dataList.cancelledBookings;
      
    },
    lastAddedBookings () {
      return this.$store.state.dataList.lastAddedBookings;
      
    },
    endedBookings () {
      return this.$store.state.dataList.endedBookings;
      
    },
    businessEventsCount () {
      return this.$store.state.businessEvents.businessEventsCount;
      
    },
    currentMonthText() {
      return moment().locale(this.$i18n.locale).startOf("month").format('MMMM');
    }
  },

  created () {
    
    this.bookingsRequest();
    this.getBriefCardsInfo();

    this.$store.dispatch('businessEvents/getBusinessEvents',{venueId: this.currentVenueId, params: {page: 1, perPage: 5}})
    window.addEventListener('changeLanguage', function(e){
      this.$store.dispatch('loader/loaderOn')
      Promise.all([ 
        this.bookingsRequest(),
      ]).then(()=>{this.$store.dispatch('loader/loaderOff')}, this);
    }.bind(this));

  },
  methods: {
    bookingsRequest() {
      this.$store.dispatch('dataList/getUpcomingBookings', {venueId: this.currentVenueId})
      .finally(()=>{this.$store.dispatch('loader/loaderOff')}, this);
    },
    newBooking() {
      this.activeTab = 0;
      this.delay(300).then(() => this.$refs.bookingsTableRef.addNewData());
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time));
    },
    getBriefCardsInfo() {
      let params = {
        event_date_type: this.informationCardsFilters.event_date_type
      }

      axios.get(`venues/${this.currentVenueId}/analytics/briefCards`, {params: params})
      .then((response) => {
        this.cardsData = response.data;
        // this.$vs.loading.close('.statistics-cards-loading> .con-vs-loading')
      })
    },

  },

  watch: {
    'informationCardsFilters.event_date_type': function() {
      this.getBriefCardsInfo();
    }
  }
}
</script>

<style lang="scss">
/*! rtl:begin:ignore */
#dashboard-analytics {
  .greet-user{
    position: relative;

    .decore-left{
      position: absolute;
      left:0;
      top: 0;
    }
    .decore-right{
      position: absolute;
      right:0;
      top: 0;
    }
  }

  @media(max-width: 576px) {
    .decore-left, .decore-right{
      width: 140px;
    }
  }
}
/*! rtl:end:ignore */
</style>
